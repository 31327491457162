import { Card, CbhIcon } from "@clipboard-health/ui-components";
import { Text } from "@clipboard-health/ui-react";
// eslint-disable-next-line no-restricted-imports
import { CardActionArea, CardContent, Chip, Stack } from "@mui/material";
import { PERSONAL_IDENTIFICATION_REQUIREMENT_ID } from "@src/appV2/Accounts/DocumentDetails/constants";
import { generateDocumentDetailsPath } from "@src/appV2/Accounts/DocumentDetails/generateDocumentDetailsPath";
import { DeprecatedGlobalAppV1Paths } from "@src/appV2/App/paths";
import { APP_V2_APP_EVENTS, logEvent } from "@src/appV2/lib/analytics";
import { useHistory } from "react-router-dom";

import {
  OnboardingRequirementStatuses,
  OnboardingRequirementTypes,
  useOnboardingRequirements,
} from "../../hooks/useOnboardingRequirements";
import { getChipProps } from "./getChipProps";

interface MandatoryCardsProps {
  workerId: string;
}

export function MandatoryCards(props: MandatoryCardsProps) {
  const { workerId } = props;
  const history = useHistory();
  const { requirements } = useOnboardingRequirements(workerId);
  const license = requirements.find(
    (requirement) => requirement.type === OnboardingRequirementTypes.LICENSE
  );
  const licenseChipProps = license ? getChipProps(license.status) : undefined;
  const idVerification = requirements.find(
    (requirement) => requirement.type === OnboardingRequirementTypes.ID_VERIFICATION
  );
  const idVerificationChipProps = idVerification ? getChipProps(idVerification.status) : undefined;

  return (
    <>
      {license ? (
        <Card variant="tertiary">
          <CardActionArea
            onClick={() => {
              logEvent(APP_V2_APP_EVENTS.PLACEMENT_ONBOARDING_PAGE_ACTIONS, {
                action: "licenses-clicked",
                status: license.status,
              });
              history.push(DeprecatedGlobalAppV1Paths.LICENSE_MANAGER);
            }}
          >
            <CardContent>
              <Stack direction="row" spacing={3} alignItems="flex-start">
                <CbhIcon type="document" />
                <Stack spacing={4} flex={1}>
                  <Stack direction="row">
                    <Text variant="h5" flex={1}>
                      License
                    </Text>
                    {licenseChipProps ? <Chip {...licenseChipProps} /> : undefined}
                  </Stack>
                  {license.status === OnboardingRequirementStatuses.REJECTED ||
                  license.status === OnboardingRequirementStatuses.MISSING ? (
                    <Text variant="body2" sx={{ color: (theme) => theme.palette.text.secondary }}>
                      Upload your license
                    </Text>
                  ) : undefined}
                </Stack>
              </Stack>
            </CardContent>
          </CardActionArea>
        </Card>
      ) : undefined}
      {idVerification ? (
        <Card variant="tertiary">
          <CardActionArea
            onClick={() => {
              logEvent(APP_V2_APP_EVENTS.PLACEMENT_ONBOARDING_PAGE_ACTIONS, {
                action: "id-verification-clicked",
                status: idVerification.status,
              });
              history.push(
                generateDocumentDetailsPath({
                  requirement: PERSONAL_IDENTIFICATION_REQUIREMENT_ID,
                })
              );
            }}
          >
            <CardContent>
              <Stack direction="row" spacing={3} alignItems="flex-start">
                <CbhIcon type="document" />
                <Stack flex={1} spacing={4}>
                  <Stack direction="row">
                    <Stack flex={1}>
                      <Text variant="h5">ID verification</Text>
                    </Stack>
                    {idVerificationChipProps ? <Chip {...idVerificationChipProps} /> : undefined}
                  </Stack>
                  {idVerification.status === OnboardingRequirementStatuses.REJECTED ||
                  idVerification.status === OnboardingRequirementStatuses.MISSING ? (
                    <Text variant="body2" sx={{ color: (theme) => theme.palette.text.secondary }}>
                      Upload your driver’s license or another type of ID
                    </Text>
                  ) : undefined}
                </Stack>
              </Stack>
            </CardContent>
          </CardActionArea>
        </Card>
      ) : undefined}
    </>
  );
}
