import { Card, CbhIcon } from "@clipboard-health/ui-components";
import { Text } from "@clipboard-health/ui-react";
// eslint-disable-next-line no-restricted-imports
import { CardActionArea, CardContent, Chip, Stack } from "@mui/material";
import { BACKGROUND_CHECK_REQUIREMENT_ID } from "@src/appV2/Accounts/DocumentDetails/constants";
import { generateDocumentDetailsPath } from "@src/appV2/Accounts/DocumentDetails/generateDocumentDetailsPath";
import { useGetRequirementStatus } from "@src/appV2/Accounts/Documents/api/useGetRequirementStatus";
import { APP_V2_APP_EVENTS, logEvent } from "@src/appV2/lib/analytics";
import { useHistory } from "react-router-dom";

import {
  getRequirementOnboardingStatus,
  OnboardingRequirementStatuses,
} from "../../hooks/useOnboardingRequirements";
import { getChipProps } from "./getChipProps";

interface BackgroundCheckCardProps {
  workerId: string;
}

export function BackgroundCheckCard(props: BackgroundCheckCardProps) {
  const { workerId } = props;
  const history = useHistory();
  const { hcpRequirementStatus, isSuccess } = useGetRequirementStatus({ workerId });
  const backgroundCheckStatus = getRequirementOnboardingStatus(
    hcpRequirementStatus,
    BACKGROUND_CHECK_REQUIREMENT_ID
  );
  const backgroundCheckChipProps = getChipProps(backgroundCheckStatus);

  return isSuccess ? (
    <Card variant="tertiary">
      <CardActionArea
        onClick={() => {
          logEvent(APP_V2_APP_EVENTS.PLACEMENT_ONBOARDING_PAGE_ACTIONS, {
            action: "background-check-clicked",
            backgroundCheckStatus,
          });
          history.push(
            generateDocumentDetailsPath({
              requirement: BACKGROUND_CHECK_REQUIREMENT_ID,
            })
          );
        }}
      >
        <CardContent>
          <Stack direction="row" spacing={3}>
            <CbhIcon type="document" />
            <Stack spacing={3} flex={1}>
              <Stack direction="row" justifyContent="space-between">
                <Text variant="h5">Background check</Text>
                {backgroundCheckChipProps ? <Chip {...backgroundCheckChipProps} /> : undefined}
              </Stack>
              {backgroundCheckStatus === OnboardingRequirementStatuses.REJECTED ||
              backgroundCheckStatus === OnboardingRequirementStatuses.MISSING ? (
                <Text variant="body2" sx={{ color: (theme) => theme.palette.text.secondary }}>
                  Start a background check with Checkr
                </Text>
              ) : undefined}
            </Stack>
          </Stack>
        </CardContent>
      </CardActionArea>
    </Card>
  ) : null;
}
