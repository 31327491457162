import { Card, CbhIcon } from "@clipboard-health/ui-components";
import { ExternalLink, Text, useModalState } from "@clipboard-health/ui-react";
import { isDefined } from "@clipboard-health/util-ts";
import { CardContent, Stack } from "@mui/material";
import { useToast } from "@src/appV2/lib";
import { APP_V2_APP_EVENTS, logEvent } from "@src/appV2/lib/analytics";
import { Button } from "@src/appV2/redesign/components/Button";
import { type Worker } from "@src/appV2/Worker/api/types";
import { AxiosError } from "axios";
import React from "react";

import { useUploadResume } from "../../api/useUploadResume";
import { ACCEPTABLE_RESUME_UPLOAD_FILE_CONTENT_TYPES } from "../../constants";
import { type PlacementCandidate } from "../../types";
import { CreateCandidateResumeModal } from "../CreateCandidateResumeModal";
import { DeleteCandidateResumeModal } from "../DeleteCandidateResumeModal";
import { PendingResume } from "../PendingResume";
import { PortResumeDialog } from "../PortResumeDialog";

interface UploadCandidateResumeCardProps {
  placementCandidate: PlacementCandidate;
  onResumeUploaded: () => Promise<void>;
  onResumeDeleted: () => Promise<void>;
  worker: Worker;
}

export function UploadCandidateResumeCard(props: UploadCandidateResumeCardProps) {
  const { placementCandidate, onResumeUploaded, onResumeDeleted, worker } = props;
  const { showSuccessToast, showErrorToast } = useToast();
  const { mutateAsync: uploadResume, isLoading: isUploadingResume } = useUploadResume();
  const fileInputRef = React.useRef<HTMLInputElement>(null);
  const createResumeModalState = useModalState();
  const deleteResumeModalState = useModalState();

  const pendingResumeStatus = placementCandidate.pendingResume?.status;
  const isPendingResume = placementCandidate.pendingResume && pendingResumeStatus === "pending";

  const isLoadingResume = isUploadingResume || isPendingResume;

  const handleUploadResume = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!isDefined(placementCandidate)) {
      return;
    }

    const file = event.target.files?.[0];
    if (!file) {
      showErrorToast("No file selected");
      return;
    }

    if (!ACCEPTABLE_RESUME_UPLOAD_FILE_CONTENT_TYPES.includes(file.type)) {
      logEvent(APP_V2_APP_EVENTS.PLACEMENT_CANDIDATE_RESUME_UPLOAD_INVALID_FILE_TYPE, {
        fileType: file.type,
        fileName: file.name,
        placementCandidateId: placementCandidate.id,
      });
      showErrorToast("Please upload a valid resume file in PDF, JPEG, or PNG format.");
      return;
    }

    try {
      await uploadResume({
        file,
        placementCandidateId: placementCandidate.id,
      });
      await onResumeUploaded();
    } catch (error) {
      if (error instanceof AxiosError) {
        showErrorToast(error.message);
      } else {
        showErrorToast("Failed to upload your resume");
      }
    }
  };

  return (
    <Card variant="tertiary">
      <PortResumeDialog
        placementCandidate={placementCandidate}
        worker={worker}
        onResumeUploaded={onResumeUploaded}
      />
      <CardContent>
        <Stack direction="row" spacing={3}>
          <CbhIcon type="resume" />
          <Stack spacing={6}>
            <Stack spacing={4} flex={1}>
              <Text variant="h5">Resume</Text>
              {placementCandidate.resume?.resumeUrl ? undefined : (
                <Text variant="body2" sx={{ color: (theme) => theme.palette.text.secondary }}>
                  Create or upload your latest resume in PDF, JPEG, or PNG format
                </Text>
              )}
              <Stack direction="row" alignItems="center" spacing={4} sx={{ flexShrink: 0 }}>
                <Button
                  component="label"
                  variant="outlined"
                  disabled={isLoadingResume}
                  size="small"
                >
                  {placementCandidate.resume?.resumeUrl ? "Re-upload" : "Upload"}
                  <input
                    ref={fileInputRef}
                    type="file"
                    style={{
                      clip: "rect(0 0 0 0)",
                      clipPath: "inset(50%)",
                      height: 1,
                      overflow: "hidden",
                      position: "absolute",
                      bottom: 0,
                      left: 0,
                      whiteSpace: "nowrap",
                      width: 1,
                    }}
                    onClick={() => {
                      // Reset the value when clicking to ensure onChange always triggers
                      if (fileInputRef.current) {
                        fileInputRef.current.value = "";
                      }
                    }}
                    onChange={handleUploadResume}
                  />
                </Button>

                {placementCandidate.resume?.resumeUrl ? (
                  <>
                    <Button
                      component={ExternalLink}
                      href={placementCandidate.resume?.resumeUrl}
                      variant="outlined"
                      size="small"
                      disabled={isLoadingResume}
                    >
                      View
                    </Button>
                    <Button
                      variant="destructive"
                      size="small"
                      disabled={isLoadingResume}
                      onClick={deleteResumeModalState.openModal}
                    >
                      Delete
                    </Button>
                    <DeleteCandidateResumeModal
                      placementCandidate={placementCandidate}
                      modalState={deleteResumeModalState}
                      onResumeDeleted={onResumeDeleted}
                    />
                  </>
                ) : (
                  <>
                    <Button
                      variant="outlined"
                      size="small"
                      disabled={isLoadingResume}
                      onClick={() => {
                        createResumeModalState.openModal();
                        logEvent(APP_V2_APP_EVENTS.PLACEMENT_CANDIDATE_CREATE_RESUME_CLICKED, {
                          placementCandidateId: placementCandidate.id,
                        });
                      }}
                    >
                      Create
                    </Button>
                    <CreateCandidateResumeModal
                      placementCandidate={placementCandidate}
                      modalState={createResumeModalState}
                      onResumeUploaded={async () => {
                        await onResumeUploaded();
                        showSuccessToast("Resume uploaded successfully");
                      }}
                    />
                  </>
                )}
              </Stack>
            </Stack>
            <PendingResume
              placementCandidate={placementCandidate}
              refreshCallback={onResumeUploaded}
            />
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  );
}
