import { Card, CbhIcon } from "@clipboard-health/ui-components";
import { ExternalLink, Text, useModalState } from "@clipboard-health/ui-react";
import { isDefined } from "@clipboard-health/util-ts";
import { CardActionArea, CardContent, Stack } from "@mui/material";
import { environmentConfig } from "@src/appV2/environment";
import { openBrowser } from "@src/appV2/lib";
import { APP_V2_APP_EVENTS, logEvent } from "@src/appV2/lib/analytics";
import { Button } from "@src/appV2/redesign/components/Button";
import { type Worker } from "@src/appV2/Worker/api/types";

import { UploadCandidateRequirementsDialog } from "../UploadCandidateRequirementsDialog";

interface UploadIntroductionVideoCardProps {
  placementCandidateId: string;
  introductionUrl?: string;
  worker: Worker;
}

export function UploadIntroductionVideoCard(props: UploadIntroductionVideoCardProps) {
  const { introductionUrl, worker, placementCandidateId } = props;
  const reuploadRequirementModalState = useModalState();

  const renderContent = () => (
    <CardContent>
      <Stack direction="row" alignItems="flex-start" spacing={3}>
        <CbhIcon type="introduction-video" />
        <Stack spacing={4} flex={1}>
          <Stack direction="row" justifyContent="space-between">
            <Text variant="h5">Introduction Video</Text>
          </Stack>
          {isDefined(introductionUrl) ? (
            <Stack direction="row" spacing={4} alignItems="center">
              <Button
                variant="outlined"
                size="small"
                onClick={() => {
                  logEvent(APP_V2_APP_EVENTS.PLACEMENT_CANDIDATE_VIDEOASK_VIA_BROWSER_CLICKED, {
                    action: "edit",
                    introductionUrl,
                    placementCandidateId,
                  });
                  reuploadRequirementModalState.openModal();
                }}
              >
                Edit
              </Button>
              <Button
                variant="outlined"
                size="small"
                component={ExternalLink}
                href={introductionUrl}
                onClick={() => {
                  logEvent(APP_V2_APP_EVENTS.PLACEMENT_CANDIDATE_VIDEOASK_VIA_BROWSER_CLICKED, {
                    action: "view",
                    introductionUrl,
                    placementCandidateId,
                  });
                }}
              >
                View
              </Button>
            </Stack>
          ) : (
            <Text variant="body2" sx={{ color: (theme) => theme.palette.text.secondary }}>
              Upload a short video
            </Text>
          )}
        </Stack>
      </Stack>
    </CardContent>
  );

  return (
    <Card variant="tertiary">
      {isDefined(introductionUrl) ? (
        renderContent()
      ) : (
        <CardActionArea
          onClick={async () => {
            logEvent(APP_V2_APP_EVENTS.PLACEMENT_CANDIDATE_VIDEOASK_VIA_BROWSER_CLICKED, {
              placementCandidateId,
              source: "update_profile_page",
            });
            await openBrowser(
              `${environmentConfig.REACT_APP_VIDEOASK_UPLOAD_URL}#worker_id=${worker.userId}&contact_name=${worker.name}&contact_email=${worker.email}`
            );
          }}
        >
          {renderContent()}
        </CardActionArea>
      )}
      {reuploadRequirementModalState.modalIsOpen && (
        <UploadCandidateRequirementsDialog
          placementCandidateId={placementCandidateId}
          modalState={reuploadRequirementModalState}
          worker={worker}
        />
      )}
    </Card>
  );
}
