import { type ChipProps } from "@mui/material";

import {
  type OnboardingRequirementStatus,
  OnboardingRequirementStatuses,
} from "../../hooks/useOnboardingRequirements";

export function getChipProps(status: OnboardingRequirementStatus): ChipProps | undefined {
  if (status === OnboardingRequirementStatuses.COMPLETED) {
    return {
      label: "Approved",
      sx: {
        backgroundColor: (theme) => theme.palette.intent?.success.background,
        color: (theme) => theme.palette.intent?.success.text,
        position: "absolute",
        right: "1rem",
        top: "0.75rem",
      },
    };
  }

  if (status === OnboardingRequirementStatuses.IN_PROGRESS) {
    return {
      label: "In review",
      sx: {
        backgroundColor: (theme) => theme.palette.intent?.neutral.background,
        color: (theme) => theme.palette.intent?.neutral.text,
        position: "absolute",
        right: "1rem",
        top: "0.75rem",
      },
    };
  }

  if (status === OnboardingRequirementStatuses.REJECTED) {
    return {
      label: "Rejected",
      sx: {
        backgroundColor: (theme) => theme.palette.intent?.error.background,
        color: (theme) => theme.palette.intent?.error.text,
        position: "absolute",
        right: "1rem",
        top: "0.75rem",
      },
    };
  }

  return undefined;
}
