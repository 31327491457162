import { Skeleton, Stack } from "@mui/material";

export function OnboardingSkeleton() {
  return (
    <Stack spacing={6}>
      <Stack>
        <Skeleton width="100%" height="56px" />
        <Skeleton width="80%" height="32px" />
      </Stack>
      <Stack spacing={4}>
        <Skeleton width="100%" height="48px" sx={{ transform: "none" }} />
        <Skeleton width="100%" height="48px" sx={{ transform: "none" }} />
      </Stack>
    </Stack>
  );
}
