import { PageHeaderWrapper } from "@clipboard-health/ui-components";
import { type UseModalState } from "@clipboard-health/ui-react";
import { isDefined } from "@clipboard-health/util-ts";
import { DialogContent } from "@mui/material";
import { FullScreenDialog } from "@src/appV2/lib/Dialogs/FullScreen";
import { useDefinedWorker } from "@src/appV2/Worker/useDefinedWorker";

import { PageHeaderTopRow } from "../../components/PageHeaderTopRow";
import { useCreateOrGetPlacementCandidate } from "../api/useCreateOrGetPlacementCandidate";
import { useOnboardingRequirements } from "../hooks/useOnboardingRequirements";
import { PlacementCandidateError } from "./PlacementCandidateError";
import { OnboardingSkeleton } from "./PlacementCandidateOnboarding/OnboardingSkeleton";
import { PlacementCandidateOnboardingContainer } from "./PlacementCandidateOnboardingContainer";
import { PlacementCandidateSkeleton } from "./PlacementCandidateSkeleton";

interface PlacementCandidateProfileModalProps {
  modalState: UseModalState;
}

export function PlacementCandidateProfileModal(props: PlacementCandidateProfileModalProps) {
  const { modalState } = props;

  const worker = useDefinedWorker();

  const {
    data: placementCandidate,
    isLoading: isPlacementCandidateLoading,
    isSuccess: isPlacementCandidateSuccess,
  } = useCreateOrGetPlacementCandidate(
    { workerId: worker.userId },
    { enabled: isDefined(worker.userId), staleTime: 60 * 1000 }
  );
  const { isLoading: isOnboardingRequirementsLoading } = useOnboardingRequirements(worker.userId);

  if (isPlacementCandidateLoading) {
    return <PlacementCandidateSkeleton />;
  }

  if (!isPlacementCandidateSuccess || !isDefined(placementCandidate.id)) {
    return <PlacementCandidateError />;
  }

  return (
    <FullScreenDialog skipTopSafeAreaPadding modalState={modalState} variant="primary">
      <PageHeaderWrapper variant="primary">
        <PageHeaderTopRow />
      </PageHeaderWrapper>
      <DialogContent>
        {isOnboardingRequirementsLoading ? (
          <OnboardingSkeleton />
        ) : (
          <PlacementCandidateOnboardingContainer
            placementCandidate={placementCandidate}
            worker={worker}
          />
        )}
      </DialogContent>
    </FullScreenDialog>
  );
}
