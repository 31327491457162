import { Text } from "@clipboard-health/ui-react";
import { isDefined } from "@clipboard-health/util-ts";
import { Stack } from "@mui/material";
import { logEvent } from "@src/appV2/lib/analytics";
import { useLogEffect } from "@src/appV2/lib/analytics/useLogEffect";
import { PullToRefresh } from "@src/appV2/lib/PullToRefresh/PullToRefresh";
import { type Worker } from "@src/appV2/Worker/api/types";
import { debounce } from "lodash";
import { useMemo } from "react";

import { APP_V2_APP_EVENTS, useToast } from "../../../lib";
import { useCreateOrGetPlacementCandidate } from "../api/useCreateOrGetPlacementCandidate";
import { useUpdatePlacementCandidate } from "../api/useUpdatePlacementCandidate";
import { useOnboardingRequirements } from "../hooks/useOnboardingRequirements";
import { type PlacementCandidate } from "../types";
import { JobPreferenceForm } from "./JobPreferenceForm";
import { type JobPreferenceFormData } from "./JobPreferenceFormSchema";
import { BackgroundCheckCard } from "./PlacementCandidateOnboarding/BackgroundCheckCard";
import { MandatoryCards } from "./PlacementCandidateOnboarding/MandatoryCards";
import { UploadCandidateResumeCard } from "./PlacementCandidateOnboarding/UploadCandidateResumeCard";
import { UploadIntroductionVideoCard } from "./PlacementCandidateOnboarding/UploadIntroductionVideoCard";

interface PlacementCandidateOnboardingContainerProps {
  placementCandidate: PlacementCandidate;
  worker: Worker;
}

export function PlacementCandidateOnboardingContainer(
  props: PlacementCandidateOnboardingContainerProps
) {
  const { placementCandidate, worker } = props;
  const { showSuccessToast } = useToast();
  const {
    numberOfCompletedSteps,
    requirements,
    isLoading: isOnboardingRequirementsLoading,
    onboardingProgress,
  } = useOnboardingRequirements(worker.userId);
  const { refetch: refetchPlacementCandidate } = useCreateOrGetPlacementCandidate(
    { workerId: worker.userId },
    { enabled: isDefined(worker.userId) }
  );

  useLogEffect(
    APP_V2_APP_EVENTS.PLACEMENT_ONBOARDING_PAGE_VIEWED,
    {
      placementCandidateId: placementCandidate?.id,
      requirements,
      onboardingProgress,
      numberOfCompletedSteps,
    },
    {
      enabled: isDefined(placementCandidate?.id) && !isOnboardingRequirementsLoading,
    }
  );

  const { mutateAsync: updatePlacementCandidate } = useUpdatePlacementCandidate();

  const debouncedUpdatePreferences = useMemo(
    () =>
      debounce(async (data: JobPreferenceFormData) => {
        logEvent(APP_V2_APP_EVENTS.PLACEMENT_CANDIDATE_JOB_PREFERENCES_SUBMITTED, {
          placementCandidateId: placementCandidate.id,
          status: placementCandidate.status,
          jobTypes: data.jobTypes,
          shiftTypes: data.shiftTypes,
          preferredAreas: data.addressObject,
        });

        await updatePlacementCandidate({
          placementCandidateId: placementCandidate.id,
          jobTypesInterested: data.jobTypes,
          shiftTypesInterested: data.shiftTypes,
          preferredAreas: data.addressObject ? [data.addressObject] : undefined,
        });

        showSuccessToast("Your job preferences have been updated.");
        await refetchPlacementCandidate();
      }, 500),
    [
      placementCandidate.id,
      placementCandidate.status,
      refetchPlacementCandidate,
      updatePlacementCandidate,
      showSuccessToast,
    ]
  );

  return (
    <>
      <PullToRefresh onRefresh={refetchPlacementCandidate} />
      <Stack spacing={8}>
        <Stack spacing={2}>
          <Text variant="h3">
            Finish setting up your Hiring Profile ({numberOfCompletedSteps}/{requirements.length})
          </Text>
          <Text variant="h5" sx={{ color: (theme) => theme.palette.text.secondary }}>
            Each step takes only a few minutes
          </Text>
        </Stack>
        <Stack sx={{ overflowY: "auto" }} flex={1} spacing={8}>
          <Stack spacing={6}>
            <Text sx={{ color: (theme) => theme.palette.text.tertiary }}>
              Mandatory for permanent jobs
            </Text>
            <MandatoryCards workerId={worker.userId} />
          </Stack>
          <Stack spacing={6}>
            <Text sx={{ color: (theme) => theme.palette.text.tertiary }}>
              Recommended for permanent jobs
            </Text>
            <UploadCandidateResumeCard
              placementCandidate={placementCandidate}
              worker={worker}
              onResumeUploaded={async () => {
                await refetchPlacementCandidate();
              }}
              onResumeDeleted={async () => {
                await refetchPlacementCandidate();
                showSuccessToast("Resume deleted successfully");
              }}
            />

            <BackgroundCheckCard workerId={worker.userId} />

            <UploadIntroductionVideoCard
              placementCandidateId={placementCandidate.id}
              introductionUrl={placementCandidate.introductionUrl}
              worker={worker}
            />

            <JobPreferenceForm
              initialJobTypes={placementCandidate.jobTypesInterested}
              initialShiftTypes={placementCandidate.shiftTypesInterested}
              initialAddressDetails={
                placementCandidate.preferredAreas?.[0] ??
                (isDefined(worker.address) && isDefined(worker.geoLocation)
                  ? {
                      address: worker.address,
                      location: {
                        type: "Point",
                        coordinates: [
                          worker.geoLocation.coordinates[0],
                          worker.geoLocation.coordinates[1],
                        ],
                      },
                      distance: worker.preference.distance,
                    }
                  : undefined)
              }
              worker={worker}
              onPreferencesUpdated={debouncedUpdatePreferences}
            />
          </Stack>
        </Stack>
      </Stack>
    </>
  );
}
